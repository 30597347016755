
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import DxTextBox from "devextreme-vue/text-box";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import EnumControl from "@/components/controls/base/EnumControl.vue";
import CatalogueSelectControl from "@/components/controls/base/CatalogueSelectControl.vue";
import { FeatureType } from "./FeatureType";
import TextControl from "@/components/controls/base/TextControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    DxTextBox,
    EnumControl,
    CatalogueSelectControl,
    TextControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
  },
  computed: {
    fieldDisplay(): any {
      const model = this.dialog().createEditModel;
      if (
        model.type == FeatureType.Enum ||
        model.type == FeatureType.DCatalogue ||
        model.type == FeatureType.Catalogue ||
        model.type == FeatureType.DataField
      )
        return true;
      else return false;
    },
  },
  data() {
    return {
      FeatureType: FeatureType,
    };
  },
});
